<template>
    <div class="q-px-md">
        <br>
        <span class="text-h5">Ricerca Clienti</span>
        <br>
        <div class="row">

            <div class="text-center q-pa-md q-gutter-sm absolute-center"
                style="z-index: 1000"
                v-show="sta_caricando">
                <q-spinner-hourglass
                    size="4em"
                />
            </div>

            <div class="col q-mr-sm">
                <q-input outlined v-model="Cognome" label="Cognome / Ragione Sociale" class="q-mb-sm" />
                <q-input outlined v-model="CodiceFiscale" label="Codice Fiscale" />
            </div>

            <div class="col q-mr-sm">
                <q-input outlined v-model="Nome" label="Nome" class="q-mb-sm" />
                <q-input outlined v-model="PartitaIva" label="Partita IVA" />
            </div>
        </div>
        <div align="center">
            <br>
            <QQButton label="CERCA" color="blue-grey" icon="mdi-file-find-outline" size="sm"
                @click.native = "onCercaClienti"
            />
        </div>
        <hr style='color: #fff;'>
        <q-table
            title = "Clicca su un Cliente per visualizzare la scheda"
            :data="righe"
            :columns="colonne"
            :filter="filter"
            dense
            row-key="name"
            class="my-sticky-header-table no-box-shadow q-mt-none"
            @row-click="onSelezionaCliente" >

            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                        style="font-size: 14px; border-bottom: 1px SOLID #404040; background-color: #ffcc99; color: #000;">
                        <strong>{{ col.label }}</strong>
                    </q-th>
                </q-tr>
            </template>

        </q-table>
        <br><br><br><br><br>
    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    import { mapActions } from "vuex";
    import {mapFields} from "vuex-map-fields"

    export default {
        name: "CercaCliente",
        data() {
            return {
                sta_caricando: false,
                Cognome: "",
                Nome: "",
                CodiceFiscale: "",
                PartitaIva: "",
                righe: [],
                colonne: [
                    { name: "IntermediarioCapogruppo", label: "Interm. Capogruppo", field: "intermediario_capogruppo", sortable: true, align: "left" },
                    { name: "Cognome", label: "Cognome", field: "cognome", sortable: true, align: "left" },
                    { name: "Nome", label: "Nome", field: "nome", sortable: true, align: "left" },
                    { name: "RagioneSociale", label: "Ragione Sociale", field: "ragione_sociale", sortable: true, align: "left" },
                    { name: "CodiceFiscale", label: "Codice Fiscale", field: "codice_fiscale", sortable: true, align: "left" },
                    { name: "PartitaIva", label: "Partita IVA", field: "partita_iva", sortable: true, align: "left" },
                ],
                filter: ""
            }
        },
        components: {
            QQButton
        },
        computed: {
            isTabellaVisible() {
                return this.righe.length > 0;
            },
            ...mapFields("gestioneClienti", {
                cliente_selezionato: "cliente_selezionato",
                elenco_pratiche_cliente: "elenco_pratiche_cliente"
            }),
        },
        methods: {
            async onSelezionaCliente(event,row) {
                console.log(event); // row contiene i dati del record selezionato
                this.cliente_selezionato = await this.fetchDatiCliente(row.id);
                await this.fetchElencoPraticheCliente(row.id);
                await this.fetchElencoPreventiviCliente(row.id);

                this.$router.push({name :"Clienti.SchedaCliente"});
            },
            onCancellaCampiRicerca() {
                this.Cognome = "";
                this.Nome = "";
                this.CodiceFiscale = "";
                this.PartitaIva = "";
            },
            isQuestionarioCompilato() {
                return (this.Cognome !== "") ||
                       (this.Nome !== "") ||
                       (this.CodiceFiscale !== "") ||
                       (this.PartitaIva !== "");
            },
            async onCercaClienti() {
                this.righe = [];
                this.sta_caricando = true;

                if (! this.isQuestionarioCompilato()) {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Nessun dato di ricerca inserito'
                    });
                    this.sta_caricando = false;
                    return;
                }

                var elenco = await this.fetchElencoClienti(
                    {
                        id: this.id,
                        Cognome: this.Cognome,
                        Nome: this.Nome,
                        CodiceFiscale: this.CodiceFiscale,
                        PartitaIva: this.PartitaIva
                    }
                );

                // scansiona per riempire le righe della tabella con tutti i risultati
                console.log("cliente:",elenco);
                elenco.forEach(cliente => {
                    this.righe.push({
                        id: cliente.id,
                        intermediario_capogruppo: cliente.intermediario_capogruppo,
                        cognome: cliente.cognome,
                        nome: cliente.nome,
                        ragione_sociale: cliente.ragione_sociale,
                        codice_fiscale: cliente.codice_fiscale,
                        partita_iva: cliente.partita_iva
                    });
                });

                this.sta_caricando = false;
            },
            ...mapActions({
                fetchElencoClienti: "gestioneClienti/fetchElencoClienti",
                fetchDatiCliente: "gestioneClienti/fetchDatiCliente",
                fetchElencoPraticheCliente: "gestioneClienti/fetchElencoPraticheCliente",
                fetchElencoPreventiviCliente: "gestioneClienti/fetchElencoPreventiviCliente"
            }),
        }
    }
</script>
